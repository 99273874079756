import React from 'react'
import { Box, Image, Heading, Text } from '@chakra-ui/react'

const NoData = ({title, text, visible,  ...rest}) => {

    if(visible)
    {
        return(        
            <Box d="flex" flexDir="column"  p="2" minH="500px" alignItems="center" justifyContent="center">
                <Image src="/assets/no-data.svg" width="100px" h="auto" alt={title} title={title} />
                <Heading mt="5"  color="brand.500" fontSize="18px">{title}</Heading>
                <Text>{text}</Text>
            </Box>
        )
    }
    return(<></>)
}

export { NoData }