import React, {useState, useEffect} from 'react'
import {
    Box, Input, InputGroup, FormControl, FormErrorMessage, FormLabel, InputLeftElement, InputRightElement,
    useBoolean, IconButton, HStack, Button,
    Popover, PopoverTrigger, PopoverContent, PopoverBody
} from '@chakra-ui/react'
import { Icon } from '.'

const TimePicker = ({label, error, onChange,  placeholder, size,  format, icon, variant, selected, ...rest}) => {
    
    const [isOpen, setIsOpen] = useState(false)
    const [value, setValue] = useState(selected || '')
    const [ clear, setClear ] = useBoolean(true) 
    const [ state, setState ] = useState({hour: '01', min: '00', a: 'AM'})


    useEffect(() => {
        if(selected){
            setValue(selected)
            if(clear){
                setClear.off()
            }           
        }else{
            setClear.on()
        }
    }, [selected, clear, setClear])

    useEffect(() => {
        if(state){            
            setValue(state?.hour +':'+state?.min+ ' '+state?.a)
        }
    }, [state])

    function clearDate (e) {
        e.preventDefault()        
        setValue('')
        onChange(null)
        setClear.on()
    }

    function padLeadingZeros(num, size) {
        var s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

    const getHours = () => {
        let elements = []
        for(let i=1; i<=12; i++){
            elements.push(
                <Button key={i} my="1" {...(state?.hour === padLeadingZeros(i,2)) && {bg: 'brand.500', color: 'white', _hover:{bg: 'brand.500', color: 'white'} }} size="xs" d="block" onClick={() => handleTimeChange('hour', padLeadingZeros(i,2))} variant="ghost">
                    {padLeadingZeros(i, 2)}
                </Button>
            )
        }
        return elements;
    }
    const getMinutes = () => {
        let elements = []
        for(let i=0; i<=59; i++){
            elements.push(
                <Button key={i}  my="1" {...(state?.min === padLeadingZeros(i,2)) && {bg: 'brand.500', color: 'white', _hover:{bg: 'brand.500', color: 'white'} }} size="xs" d="block" onClick={() => handleTimeChange('min', padLeadingZeros(i,2))}  variant="ghost">
                    {padLeadingZeros(i, 2)}
                </Button>
            )
        }
        return elements;
    }

    const handleTimeChange = (name, value) => {
        setState({...state, [name]: value}) 
        let time = {...state, [name]: value} 
        onChange(time?.hour+':'+time?.min+' '+time?.a)
    }

    return(
        <>
        <Box pos="relative">
            <Popover
                // matchWidth={true}
                isOpen={isOpen}
                gutter="0"        
                placement="bottom-start"
                onClose={() => setIsOpen(false)}
                
                >
                <PopoverTrigger>
                    <FormControl isInvalid={error}>
                        { label && <FormLabel fontSize="12px" color="brand.600" fontWeight="600" textTransform="uppercase">{label}</FormLabel>}
                        <InputGroup size={size || 'md'}>
                            {
                                (icon) &&
                                <InputLeftElement 
                                    pointerEvents="none"
                                    children={<Icon name={icon}  />}
                                />                                
                            } 
                            <Input variant={variant}  onClick={() => setIsOpen(true)} placeholder={placeholder || "Select Date"} readOnly value={ clear ? '' :value } />                
                            {
                                !clear &&
                                <InputRightElement                             
                                    children={<IconButton onClick={(e) => clearDate(e)} rounded="full" variant="ghost" colorScheme="gray" size="xs" icon={<Icon fontSize="xs" name="times-solid" color="currentcolor" />} />}
                                /> 
                            }                        
                        </InputGroup>
                        <FormErrorMessage>{error}</FormErrorMessage>
                    </FormControl>    
                </PopoverTrigger>
                <PopoverContent w="100%" rounded="0" _focus={{ shadow: 'none' }}>
                    <PopoverBody>
                        <HStack spacing="4" alignItems="flex-start"> 
                            <Box overflowY="auto" maxH="150px"  w="50px">
                                {getHours()}
                            </Box>
                            <Box overflowY="auto" maxH="150px"   w="50px">
                                {getMinutes()}
                            </Box>
                            <Box overflowY="auto" maxH="150px"   w="50px">
                                <Button my="1" {...(state?.a === 'AM') && {bg: 'brand.500', color: 'white', _hover:{bg: 'brand.500', color: 'white'} }} size="xs" d="block" onClick={() => handleTimeChange('a', 'AM')}  variant="ghost">AM</Button>
                                <Button my="1" {...(state?.a === 'PM') && {bg: 'brand.500', color: 'white', _hover:{bg: 'brand.500', color: 'white'} }} size="xs" d="block" onClick={() => handleTimeChange('a', 'PM')}variant="ghost">PM</Button>
                            </Box>
                        </HStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Box>
        </>
    )
}

export { TimePicker }