// import { datepicker } from "./datepicker"

export const custom = {
    'html, body': {
        color: 'gray.500',
        },
    '.dash-nav': {
            '&.active': {
                borderColor: 'brand.500',
                backgroundColor: 'gray.50',
                color: 'brand.500',                
                '&:hover': {
                    borderColor: 'brand.500',
                    backgroundColor: 'gray.50',
                    color: 'brand.500',
                }            
            },
            
        },
    '.hover-reveal':{
            transition: 'all 0.3s linear',            
            '&:hover':{
                '.hover-target':{
                    d: 'flex'
                }
            }
        },
    '::-webkit-scrollbar':{
            bg: '#f2f2f2',
            w: '4px',
            h: '4px'
        },            
    '::-webkit-scrollbar-thumb': {
            bg: '#C9C9C9',
            borderRadius: '5px'
        },            
    '::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-thumb:active':{
            cursor: 'pointer'
        },
    "table.minimal":{
            w: "100%",
            bg: "white",
            position: "relative",
            thead:{   
                borderColor: "gray.100",                        
                th:{
                    // top: 0,
                    bg: "white",
                    // position: "sticky",
                    textAlign: 'left',
                    fontSize: "0.9rem",                       
                    py:3,
                    px:5,
                    fontWeight: '500',
                    color: 'gray.400',                
                    borderBottom: "1px solid",
                    borderColor: "gray.100",
                    "&[data-align='right']":{
                        textAlign: "right"
                    },                
                }
            },
            tbody:{
                td:{
                    fontSize: "0.9rem",
                    py:5,
                    px:5,
                    color: 'gray.500',
                    bg: "transparent",                
                    transition: "all 0.3s ease-in",
                    verticalAlign: 'middle',
                    "&.title":{
                        fontSize: "0.95rem",
                        fontWeight: '500',
                        color: 'accent.500'
                    },
                    "&[data-align='right']":{
                        textAlign: "right"
                    },  
                    borderBottom: "1px dashed",
                    borderColor: "gray.100",              
                },
                tr:{
                    transition: "all 0.3s ease-in",
                    "&:hover":{
                        td:{
                            bg: 'gray.50'
                        }
                    }
                }
    
            }
        },   
    // ...datepicker
}   
