import {handleResponse, requestTimeout} from '../helpers'

const apiUrl = process.env.REACT_APP_API_URL
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const metaApi = {
    _get,
};


async function _get(){
    const app_token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        }        
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'metas', requestOptions))
                .then(handleResponse)
                .then( (data) => {                
                    return data;
                });    
}

