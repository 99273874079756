import { LeadConstants as Constants } from '../types'

const initialState = {
    leads: [],
    lead: null,
    total: 0
};

export function leadReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,
                leads: action.payload.leads,
                total: action.payload.count,
            };

        case Constants.DETAILS:
            return {
                ...state,
                lead: action.payload.lead                
            };
            
        case Constants.ADD:
            return {
                ...state,               
                leads: [action.payload.lead, ...state.leads],
                total: state?.total + 1
            };

        case Constants.UPDATE:
            let index = state.leads.findIndex((item) => parseInt(item.id) === parseInt(action.payload.lead.id));
            let itemsArray = [...state.leads];
            if(index > -1)
                itemsArray[index] = action.payload.lead
            return {
                ...state,               
                leads: itemsArray,
                lead: state?.lead ? action.payload.lead : state?.lead
            };
        
        case Constants.DELETE:
            console.log("Action Payload", action.payload)
            return{
                ...state,
                leads: state.leads.filter((x) => !action.payload.includes(x?.url_title)),
                total: state?.total > 0 ? state?.total - action?.payload?.length : 0
            }

        case Constants.CLEAR:
            return{
                ...state,
                lead: null,
            }
            
        case Constants.FAILED:
            return{
                ...state
            }

        default:
            return state
    }
}