import { MetaConstants as Constants } from '../types'

const initialState = {
    cities: [],
    unit_types: [],
    lead_groups: [],
    project_types: []
};

export function metaReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            let metas = action.payload.metas
            return {
                ...state,
                cities: metas.filter((obj) => obj.type === 'CITY'),
                unit_types: metas.filter((obj) => obj.type === 'UNIT'),
                lead_groups: metas.filter((obj) => obj.type === 'LEAD'),
                project_types: metas.filter((obj) => obj.type === 'PROJECT')
            };        

        default:
            return state
    }
}