import {handleResponse, requestTimeout} from '../helpers'

const apiUrl = process.env.REACT_APP_API_URL
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const roleApi = {
    _get,
    _resources,
    _add,
    _update
};


async function _get(){
    const app_token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        }        
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'roles', requestOptions))
                .then(handleResponse)
                .then( (data) => {                
                    return data;
                });    
}


async function _resources(){
    const app_token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        }        
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'resources', requestOptions))
                .then(handleResponse)
                .then( (data) => {                
                    return data;
                });    
}

async function _add(data) { 
    let app_token = localStorage.getItem(accessToken);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+app_token
        },
        body: JSON.stringify(data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'roles', requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}

async function _update(data) {
    let app_token = localStorage.getItem(accessToken);
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+app_token
        },
        body: JSON.stringify(data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'roles/'+data?.id, requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}

