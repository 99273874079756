import { UserConstants as Constants } from '../types'
import { userApi as api } from '../../api'

export const userAction = {
    get,    
    add, 
    update,
    all_teams,
    teams,
    add_team,
    delete_team,
    update_team,
    clear
};

function get() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function add(data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._add(data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.ADD, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function update(data, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._update(data, id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.UPDATE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}


function teams() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._teams()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.TEAMS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function all_teams() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._all_teams()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.ALL_TEAMS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function add_team(data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._add_team(data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.ADD_TEAM, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function update_team(data, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._update_team(data, id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.UPDATE_TEAM, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function delete_team(id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._delete_team(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.REMOVE_TEAM, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function clear() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(success());
            resolve()            
        });
    }
    function success() { return { type: Constants.CLEAR } }
}
