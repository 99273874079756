import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export const dateFormat = (date, type="") => {    
    switch(type){
        case 'fromNow':
            return dayjs(date).fromNow()
        default:
            return dayjs(date).format('DD-MM-YYYY')
    }
    
}