import React, { useState } from 'react'
import { Box, HStack, Image, FormLabel, IconButton } from '@chakra-ui/react'
import {  Icon, Tooltip } from '.'

const ImagePicker = ({multiple=false, label, onSelect,   images=[]}) => {

    const [list, setList] = useState(images || [])
    const imgRef = React.useRef();

    const handleFile = (e) => {
        if(e.target.files){
            if(multiple){
                let allFiles  = e.target.files
                let allList = []
                for (var i = 0; i < allFiles.length; i++) {
                    if(allFiles[i]?.type.includes('image/')){
                        allList.push({
                            thumb: URL.createObjectURL(allFiles[i]),
                            file: allFiles[i]
                        })
                    }    
                }                
                setList([...list, ...allList ])
                onSelect([...list, ...allList ])
            }
            else{
                let file  = e.target.files[0]
                if(file?.type.includes('image/')){
                    let img = URL.createObjectURL(file)
                    setList([...list, img])
                }
                else{
                    alert("Only image files are allowed")
                }
            }
        }else{
            alert("Please select image file")
        }
    }

    const triggerSelect = () =>{
        var event = new MouseEvent('click', {
            'view': window, 
            'bubbles': true, 
            'cancelable': false
           });           
           imgRef.current.dispatchEvent(event);
    }

    const removeImage = (index) => {
        let allData = [...list]
        allData.splice(index, 1)
        setList(allData)
        onSelect(allData)
    }

    return(
        <>
        <HStack py="2" alignItems="center">
            <Box>{label && <FormLabel m="0" fontSize="12px" color="brand.600" fontWeight="600" textTransform="uppercase">{label}</FormLabel>}</Box>
            <Tooltip label='Select Images to upload' placement="end">
                <IconButton onClick={triggerSelect} rounded="full" variant="ghost" _hover={{ bg: 'brand.400', color: 'white' }} colorScheme="brand" size="xs" icon={<Icon fontSize="sm" color="currentcolor" name="image-solid" />} />
            </Tooltip>
            <input multiple={multiple ? true : false}  accept="image/*" style={{  display: "none" }} ref={imgRef} type="file" onChange={handleFile}/>
        </HStack>        
        <Box bg="gray.50" rounded="md">
            
            { 
                (list.length === 0 || !list) ?
                <></>
                :
                <Box px="5" py="2">
                    <HStack flexWrap="wrap">
                        {
                            list.map((item, t) => 
                            <Box w="20%" key={t} pos="relative" className="hover-reveal">
                                <Image boxSize='100px' src={item?.thumb} objectFit="cover" />
                                <Box className="hover-target" d="none" pos="absolute" left="50%" top="50%" transform="translate(-50%, -50%)">
                                    <IconButton onClick={() => removeImage(t)} size="sm" rounded="full" colorScheme="red"  icon={<Icon name="times-solid" color="currentcolor" />} />
                                </Box>
                            </Box>
                            )
                        }
                    </HStack>
                </Box>
            }
        </Box>
        </>
    )
}

export { ImagePicker }