import React, { useState, useEffect } from "react";
import {
  Box,
  HStack,
  Input,
  Select,
  Text,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  FormErrorMessage

} from "@chakra-ui/react";
import Flag from "react-world-flags";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import { getCountryCode, countries } from './../utils';

const  PhoneNumberInput = ({size,value, label, error, required, country, onChange,placeholder,...rest }) => {
    
    let [number, setNumber] = useState(value || "");
    let [selectedCountry, setSelectedCountry] = useState(country || "");
    let [countryCode, setCountryCode] = useState(
        getCountryCode(country) || ""
    );

    let options = [];
    countries.map((country, c) => {
        return options.push({
            value: country.sortname, 
            label: country.name,
        })
    })
    
    useEffect(() => {
        if(value){                        
            try{
                let phoneNumber = parsePhoneNumber(value)
                if(phoneNumber){                    
                    setSelectedCountry(phoneNumber?.country);
                    setNumber(phoneNumber?.nationalNumber)                
                    setCountryCode(getCountryCode(phoneNumber?.country));
                }
            }catch(e){
                console.log("Error", e)
            }            
        }
    }, [value])

    useEffect(() => {
        if(country){
            setSelectedCountry(country);
            setCountryCode(getCountryCode(country));
        }
    }, [country]);

    const onCountryChange = e => {
        let value = e.target.value;
        let code = getCountryCode(value);
        let parsedNumber = new AsYouType().input(`${code}${number}`);
        setCountryCode(code);
        setSelectedCountry(value);
        onChange(parsedNumber);
    };

  const onPhoneNumberChange = e => {
    let value = e.target.value;    
    setNumber(value);
    onChange(countryCode, value);
  };


  return (
    <FormControl isInvalid={error}>
        {label && <FormLabel fontSize="12px" color="brand.600" fontWeight="600" textTransform="uppercase">{label} {required && <Text as={'span'} fontSize="xs" color="red">*</Text>}</FormLabel>}            
        <InputGroup size={size} {...rest}>
            <InputLeftElement width="100px">
                <Select
                    top="2"
                    left="0"
                    size="sm"
                    zIndex={1}
                    bottom={0}
                    opacity={0}
                    height="100%"
                    position="absolute"
                    value={selectedCountry}
                    onChange={onCountryChange}
                    >
                    <option value="" />
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>{option.label}</option>
                    ))}
                </Select>
                <Box mr="4px" width="50%" flex={1}>
                    <HStack>
                        <Flag height="16px" width="24px" code={selectedCountry} />
                        <Text fontSize="sm">+{countryCode}</Text>
                    </HStack>
                </Box>
                    
                
            </InputLeftElement>
            
            <Input
                pl="100px"
                value={number}
                placeholder={placeholder}
                onChange={onPhoneNumberChange}
            />
        </InputGroup>
        <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
}

export {PhoneNumberInput}