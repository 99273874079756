import {handleResponse, requestTimeout} from '../helpers'

const apiUrl = process.env.REACT_APP_API_URL
const tokenName = process.env.REACT_APP_REFRESH_TOKEN
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const authApi = {
    _login,
    _logout,
    _auth_check,
    _refresh,
    _resend_request,
    _forget_password,
    _check_reset,
    _reset_password,
    _stats,
    _statsbydate,
    _reports,
};


async function _logout(){
    const app_token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        }        
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'logout', requestOptions))
                .then(handleResponse)
                .then( () => {
                    localStorage.removeItem(tokenName);    
                    localStorage.removeItem(accessToken)
                    return true;
                });    
}

async function _login(form_data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'login', requestOptions))
                .then(handleResponse)
                .then((response) => {
                    if (response.token) {                
                        localStorage.setItem(tokenName, response.token)
                        localStorage.setItem(accessToken, response.access_token)
                    }
                    return {user: response.user, token: response.access_token};
                });
}

async function _forget_password(form_data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'forget-password', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}

async function _reset_password(form_data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'reset-password', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}

async function _stats() {
    let token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'stats', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}

async function _statsbydate(form_data) {
    let token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'stats-by-dates', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}

async function _reports(form_data) {
    let token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'reports', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}

async function _check_reset(form_data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'check-reset', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}


async function _auth_check() {
    let token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'check-auth', requestOptions))
                .then(handleResponse)
                .then((data) => {                    
                    return data;
                });
}


async function _refresh(refresh_token){

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ refresh_token
        }
    };
    if(refresh_token){
        return requestTimeout(apiMaxTime,fetch(apiUrl + 'refresh_token', requestOptions))
            .then(handleResponse)
            .then((data) => {     
                localStorage.setItem(accessToken, data?.access_token)
                return data;
            });
    }
    return Promise.reject({error: 'Unauthorized Access'})
}

async function _resend_request(url, config){
    return requestTimeout(apiMaxTime, fetch(url, config))
            .then((data) => {  
                return data;
            });
}