import React from 'react'
import { Heading, Box, HStack, IconButton } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Icon } from '.'

const PageHeader = ({title, subTitle, showBack=true, children, ...rest}) => {

    const navigate = useNavigate()

    return(
        <Box p="5">
            <HStack alignItems="center" justifyContent="space-between">
                <HStack alignItems="center">
                    {
                        showBack &&
                        <IconButton onClick={() => navigate('/')} my="3" mx="3" color="brand.500" _hover={{ textDecoration: "none", color: 'brand.700' }} size="md" rounded="full" colorScheme="gray" icon={<Icon fontSize="24px" color="currentcolor" name="arrow-left-solid" />} />
                    }
                    
                    <Box>
                    <Heading variant="page">{title}</Heading>
                    {
                        subTitle && <Heading size="sm">{subTitle}</Heading>
                    }
                    </Box>
                </HStack>
                <HStack alignItems="center" pr="5">
                    { children }
                </HStack>
            </HStack>
        </Box>
    )
}

export { PageHeader }