import { ClientConstants as Constants } from '../types'

const initialState = {
    clients: [],   
    client: null,
    total: 0 
};

export function clientReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,
                clients: action.payload.clients,
                total: action.payload.count,
            };
        
        case Constants.DETAILS:
            return {
                ...state,
                client: action.payload.client                
            };

        case Constants.FAILED:
            return{
                ...state
            }

        default:
            return state
    }
}