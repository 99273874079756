import React, { useState, useEffect } from 'react'
import { Box, FormControl, HStack, 
        IconButton, Avatar, Text, FormLabel, 
        FormErrorMessage, InputGroup, 
        FormHelperText, Input, useBoolean, 
        useOutsideClick, InputRightElement, 
        InputLeftElement } 
from '@chakra-ui/react'
import { Icon } from '.'

const SelectDropdown = React.forwardRef( ({error, size, defaultOpen, icon, info, required, label, placeholder, options, value, onChange, onSelect, ...rest}, ref) => {

    const dropdownRef = React.useRef()
    const [show, setShow] = useBoolean(defaultOpen || false)
    const [ filtered, setFiltered ] = useState(options)
    const [ innerValue, setInnerValue ] = useState('')
    const [ selected, setSelected ] = useState(null)

    useEffect(() => {
        let selectedVal = options.find(opt => opt.value === value)
        if(selectedVal){
            setInnerValue(selectedVal.label)
            setSelected(selectedVal.value)
        }else{
            setInnerValue(value)
            setSelected(null)
        }
        setFiltered(options)
    }, [value, options])

    useOutsideClick({
        ref: dropdownRef,
        handler: () => {
            if(show)
                setShow.off()
        },
      })

    const handleFilter = (q) =>{
        if(value){
            onChange('')
        }
        let filterOptions = options.filter((x) => {            
            return x.label.toUpperCase().includes(q.toUpperCase())
        })        
        setFiltered(filterOptions)
        setInnerValue(q)
    }

    const handleSelect =(opt) => {                
        onChange(opt.value)
        setInnerValue(opt.label)
        setShow.off()
    }

    const handleBlur = () => {
        if(!value){
            setInnerValue('')
        }
    }

    const clearVal = () => {
        onChange('')
        setInnerValue('')
    }

    return(
        <Box pos="relative" m="0">
            <FormControl isInvalid={error}>
                {label && <FormLabel  fontSize="12px" color="brand.600" fontWeight="600" textTransform="uppercase">{label} { required && <Text as="span" fontSize="sm" color="red">*</Text>}</FormLabel>}                
                <Box pos="relative" m="0">
                    <InputGroup size={size}>
                        {
                            icon &&
                            <InputLeftElement>
                                <Icon name={icon} />
                            </InputLeftElement>

                        }
                        <Input 
                            onFocus={setShow.on} 
                            onBlur={handleBlur} 
                            placeholder={placeholder} 
                            onClick={setShow.on} 
                            value={innerValue || ''} 
                            onChange={(e) => handleFilter(e.target.value)}                          
                            {...rest} ref={ref} 
                        />
                        <InputRightElement                             
                            children={
                                <HStack>
                                    {
                                        selected && <IconButton onClick={() => clearVal()} rounded="full" variant="ghost" colorScheme="gray" size="xs" icon={<Icon fontSize="xs" name="times-solid" color="currentcolor" />} />
                                    }                                    
                                    <Icon pointerEvents="none" transition="ease-in-out"  name={ show ? "angle-up-solid" : "angle-down-solid"} fontSize="xs" color="gray.800" />
                                </HStack>
                                }
                        />
                    </InputGroup>
                    <Box ref={dropdownRef} d={show ? "block" : 'none'} bg="white" pos="absolute" zIndex={10} left={0}  top="100%" w="100%" shadow="lg" maxH="200px" overflowY="auto">
                        {
                            (filtered?.length > 0) ? filtered.map((option, o) =>
                            <HStack key={o} onClick={() => handleSelect(option)}  px="4" _hover={{ bg: 'gray.50', color: 'brand.500' }} cursor="pointer" py="1.5">
                                {
                                    option?.thumbnail &&
                                    <Avatar 
                                        src={option?.thumbnail}
                                        // icon={<Icon color="red.500" name="user-times-solid" />}
                                        name={option.label}
                                        {...(!option?.value) && {color: 'red', bg: 'red.100', src: null, name: null, icon: <Icon color="red.500" fontSize="12px" name="times-solid" />}}  
                                        size="xs"
                                    />
                                }
                                <Text color={!option?.value ? "red" : "currentcolor"} fontSize="sm">{option?.label}</Text>                                
                            </HStack>                                
                            )
                            :
                            <Box  px="4" _hover={{ bg: 'gray.50', color: 'brand.500' }} cursor="pointer" py="1.5">
                                <Text color="currentcolor">No data found</Text>
                            </Box>
                        }
                    </Box>                                    
                </Box>
                <FormHelperText color="gray.600" fontSize="xs">{info}</FormHelperText>
                <FormErrorMessage>{error}</FormErrorMessage>                
            </FormControl>
        </Box>
    )
})

export { SelectDropdown }