import {handleResponse, requestTimeout} from '../helpers'

const apiUrl = process.env.REACT_APP_API_URL
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const projectApi = {
    _get,
    _get_key_vals,
    _get_key_vals_with_units,
    _add,
    _update,
    _delete,
};


async function _get(){
    const app_token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        }        
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'projects', requestOptions))
                .then(handleResponse)
                .then( (data) => {                
                    return data;
                });    
}

async function _get_key_vals(){
    const app_token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        }        
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'projects-keyvals', requestOptions))
                .then(handleResponse)
                .then( (data) => {                
                    return data;
                });    
}

async function _get_key_vals_with_units(){
    const app_token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        }        
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'projects-keyvals-units', requestOptions))
                .then(handleResponse)
                .then( (data) => {                
                    return data;
                });    
}



async function _add(data) { 
    let app_token = localStorage.getItem(accessToken);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+app_token
        },
        body: data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'projects', requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}

async function _update(data, id) {
    let app_token = localStorage.getItem(accessToken);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+app_token
        },
        body: data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'projects/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}

async function _delete(id) {
    let app_token = localStorage.getItem(accessToken);
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+app_token
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'projects/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}

