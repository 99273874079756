import React, { useState, useEffect } from 'react'
import { Box, FormControl, IconButton, Text, FormLabel,  InputGroup, InputRightElement, FormErrorMessage, FormHelperText, Input, useBoolean, useOutsideClick } from '@chakra-ui/react'
import { Icon } from '.'
const AutoSelect = React.forwardRef( ({error, info, required, label, placeholder, options, value, onChange, onSelect, renderOption, ...rest}, ref) => {

    const dropdownRef = React.useRef()
    const [show, setShow] = useBoolean(false)
    const [ filtered, setFiltered ] = useState(options)
    const [ innerValue, setInnerValue ] = useState(value || '')

    useEffect(() => {
        setInnerValue(value)
    }, [value])

    useOutsideClick({
        ref: dropdownRef,
        handler: () => {
            if(show)
                setShow.off()
        },
      })

    const handleFilter = (q) =>{
        let filterOptions = options.filter((x) => {            
            return x.name.toUpperCase().includes(q.toUpperCase())
        })        
        setFiltered(filterOptions)
        setInnerValue(q)
        onChange(q)
    }

    const handleSelect =(opt) => {                
        onChange(opt.name)
        setInnerValue(opt.label)
        setShow.off()
    }

    const clearVal = () => {
        onChange('')
        setInnerValue('')
    }


    return(
        <Box pos="relative" m="0">
            <FormControl isInvalid={error}>
                {label && <FormLabel  fontSize="12px" color="brand.600" fontWeight="600" textTransform="uppercase">{label} { required && <Text as="span" fontSize="sm" color="red">*</Text>}</FormLabel>}                
                <Box pos="relative" m="0">
                    <InputGroup>
                        <Input onFocus={setShow.on} placeholder={placeholder} onClick={setShow.on} value={innerValue || ''} onChange={(e) => handleFilter(e.target.value)}   {...rest} ref={ref} />
                        {
                            innerValue &&
                            <InputRightElement                             
                                children={<IconButton onClick={() => clearVal()} rounded="full" variant="ghost" colorScheme="gray" size="xs" icon={<Icon fontSize="xs" name="times-solid" color="currentcolor" />} />}                                    
                            />
                        }
                    </InputGroup>
                    <Box ref={dropdownRef} d={show ? "block" : 'none'} bg="white" pos="absolute" zIndex="modal" left={0}  top="100%" w="100%" shadow="lg" maxH="200px" overflowY="auto">
                        {
                            filtered.map((option, o) =>
                            <Box key={o} onClick={() => handleSelect(option)}  px="4" _hover={{ bg: 'gray.50', color: 'brand.500' }} cursor="pointer" py="1.5">
                                <Text color="currentcolor" fontSize="sm">{option?.name}</Text>                                
                            </Box>    
                            )
                        }
                    </Box>                                    
                </Box>
                <FormHelperText color="gray.600" fontSize="xs">{info}</FormHelperText>
                <FormErrorMessage>{error}</FormErrorMessage>                
            </FormControl>
        </Box>
    )
})

export { AutoSelect }