import { AuthConstants as Constants } from '../types'

const accessToken = process.env.REACT_APP_ACCESS_TOKEN
let token = localStorage.getItem(accessToken)

const initialState = {
    user: {},
    stats: null,
    loggedIn: false,
    token: token,
    reports: [],
};

export function authReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.LOGIN:
            return {
                ...state,
                loggedIn: true,
                token: action.payload.token,
                user: action.payload.user,
            };

        case Constants.AUTH:
            return {
                ...state,
                loggedIn: true,                
                user: action.payload.user,
            };
            
        case Constants.REFRESH_TOKEN:
            return {
                ...state,
                token: action.payload?.token || null
            }

        case Constants.REFRESH_TOKEN_FAILED:
            return {
                ...state,
                user: null,
                token: null
            }   

        case Constants.SETPROFILE:
            return{
                ...state,
                user: action.payload.user,
            };

        case Constants.STATS:
            return{
                ...state,
                stats: action.payload.stats,
            };

        case Constants.REPORTS:
            return{
                ...state,
                reports: action.payload.reports,
            };
       
        case Constants.LOGOUT:
            return{
                ...state,
                loggedIn: false,
                token: null,
                user: {},
            };
                   
        case Constants.FAILED:
            return{
                ...state
            }
        case Constants.CLEAR:
        default:
            return state
    }
}