import { RoleConstants as Constants } from '../types'

const initialState = {
    roles: [],
    resources: []
};

export function roleReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,
                roles: action.payload.roles                
            };
            
        case Constants.GET_RESOURCES:
            return {
                ...state,
                resources: action.payload?.resources
            }
        
        case Constants.ADD:
            return {
                ...state,               
                roles: [...state.roles, action.payload.role]
            };

        case Constants.UPDATE:
            let index = state.roles.findIndex((item) => parseInt(item.id) === parseInt(action.payload.role.id));
            let itemsArray = [...state.roles];
            if(index > -1)
                itemsArray[index] = action.payload.role
            return {
                ...state,               
                roles: itemsArray
            };
          
        case Constants.FAILED:
            return{
                ...state
            }

        default:
            return state
    }
}