import { LeadConstants as Constants } from '../types'
import { leadApi as api } from '../../api'

export const leadAction = {
    get,    
    details,
    clear,
    add, 
    update,
    remove,
    add_task,
    close_lead,
    import_leads,
    export_leads,
    patch
};

function get(query, filter="", offset=0) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get(query, filter, offset)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function details(slug) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._details(slug)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.DETAILS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function add(data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._add(data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.ADD, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function update(data, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._update(data, id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.UPDATE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function remove(ids) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._delete(ids)
                .then(
                    payload => {
                        dispatch(success(ids));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.DELETE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function clear() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(success());
            resolve()            
        });
    }
    function success() { return { type: Constants.CLEAR } }
}


function add_task(data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._add_task(data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.UPDATE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function close_lead(data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._close_lead(data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.UPDATE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function import_leads(data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._import_leads(data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function export_leads(data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._export_leads(data)
                .then(
                    payload => {
                        resolve(payload)
                    },
                    error => {
                        reject(error)
                    }
                )

        });
    }    
}

function patch(data, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._patch(data, id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.UPDATE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}