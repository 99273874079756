import { ProjectConstants as Constants } from '../types'

const initialState = {
    projects: [],
    project_vals: [],
    project_units: []
};

export function projectReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,
                projects: action.payload.projects                
            };

        case Constants.KEYVAL:
            return {
                ...state,
                project_vals: action.payload.vals                
            };

        case Constants.KEYVALNESTED:
            return {
                ...state,
                project_units: action.payload.vals
            }
            
        case Constants.ADD:
            return {
                ...state,               
                projects: [...state.projects, action.payload.project]
            };

        case Constants.UPDATE:
            let index = state.projects.findIndex((item) => parseInt(item.id) === parseInt(action.payload.project.id));
            let itemsArray = [...state.projects];
            if(index > -1)
                itemsArray[index] = action.payload.project
            return {
                ...state,               
                projects: itemsArray
            };
        
        case Constants.DELETE:
            return{
                ...state,
                projects: state.projects.filter((x) => parseInt(x.id) !== parseInt(action.payload.id))
            }

        case Constants.FAILED:
            return{
                ...state
            }

        default:
            return state
    }
}