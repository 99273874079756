import React, { createContext, useEffect, useState } from 'react';
import { Ability as CaslAbility, AbilityBuilder } from '@casl/ability';
import { useSelector, useDispatch } from 'react-redux';
import { authAction, metaAction } from '../redux/actions';
import { createCanBoundTo } from '@casl/react'
import { useBoolean } from '@chakra-ui/react';

const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const  Ability = new CaslAbility([]);
const Can = createCanBoundTo(Ability);


const CanTest = ({I, a, or, fallback, children,...rest}) => {
    let permits = I.split('/');
    let is_allowed = false
    permits.map((permit) => {
        if(Ability.can(permit, a)){
            is_allowed = true
        }
        if(or){
            if(Ability.can(permit, or)){
                is_allowed = true
            }   
        }
        return is_allowed;
    })

    if(is_allowed){    
        return(
            <>{children}</>
        )
    }else{
        return <>{fallback}</>
    }
}
const initialState = {
    user: null,       
    token: localStorage.getItem(accessToken)
};

const AppContext = createContext({
    ...initialState,
    init: () => Promise.resolve(),
    login: () => Promise.resolve(),     
    logout: () => Promise.resolve(),
});



export const AppProvider = (props) => {
    const auth = useSelector(state => state.auth);
    const [loading, setLoading ] = useBoolean(true)
    const {cities, unit_types, lead_groups, project_types} = useSelector(state => state.metas)
    const [state, setState] = useState({...initialState, ...auth})
    const dispatch = useDispatch()
    

    useEffect(() => {
      init()
      // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [])

    useEffect(() => {
        setState({
            ...state,
            ...auth
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth])

    useEffect(() => {
        if(auth?.user && auth?.user?.roles){
            let roles = auth?.user?.roles;
            const { can, rules } = new AbilityBuilder();
            roles.map((role) => {
                let permissions = role?.role?.permissions
                return Object.entries(permissions).forEach(([key, val]) => {                                                
                        let actions = [];
                        Object.entries(val).forEach(([key2, val2]) => {
                            if(val2){
                                actions.push(key2)
                                can(key2, key);
                            }
                        })
                    });
                
            })   
            
            console.log("Rules", rules)
            Ability.update(rules);         
        }     
        if(auth?.user && auth?.user?.is_admin){
            const { can, rules } = new AbilityBuilder();
            can('manage', 'all');
            Ability.update(rules);    
        }           
    }, [auth?.user])


    const init =  () => {     
        setTimeout(async function() { await loader() }, 1500);
        // await loader()
    }

    const login = (form_data) => {
        return new Promise((resolve, reject) => {
            dispatch(authAction.clear())
            .then(() => {
                return dispatch(authAction.login(form_data))
            })            
            .then((response) => {
                resolve(response) 
            })
            .catch((error) => reject(error) )
        })

    }

    const logout = () => {
        return new Promise((resolve, reject) => {
        dispatch(authAction.logout())
            .then(() => {
                setState({...state, user:null, token: null})                
                resolve()
            })
            .catch((e) => {
                reject()
            })
        })

    }

    


    /* Check initial authentication here */
    const loader =  async () => {          
        try{
            
            await dispatch(authAction.auth_check())
            await dispatch(metaAction.get())
            setLoading.off()
            setState({...state, token: localStorage.getItem(accessToken)})
        }
        catch(err){
            console.log("error", err)
            setLoading.off()            
            localStorage.removeItem(accessToken)
            setState({...state, user: null, token: null, loading: false})
            
            // console.log("Error 2", err)
            
        }
    }
    
    return (
        <AppContext.Provider
            value={{
                ...state,
                loading,
                cities,
                lead_groups,
                project_types,
                unit_types,
                Ability,
                Can,
                CanTest,
                login,
                logout
            }}>
            {props.children}
            
        </AppContext.Provider>
    );
}

export  {AppContext}