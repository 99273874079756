import {handleResponse, requestTimeout} from '../helpers'

const apiUrl = process.env.REACT_APP_API_URL
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const leadApi = {
    _get,
    _details,
    _add,
    _update,
    _delete,
    _add_task,
    _close_lead,
    _import_leads,
    _export_leads,
    _patch

};


async function _get(query, filter, offset){
    const app_token = localStorage.getItem(accessToken)
    const form_data = {...query, filter: filter || ""}
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        },
        body: JSON.stringify(form_data)       
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'leads/search/'+offset, requestOptions))
                .then(handleResponse)
                .then( (data) => {                
                    return data;
                });    
}

async function _details(slug){
    const app_token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        }        
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'leads/'+slug, requestOptions))
                .then(handleResponse)
                .then( (data) => {                
                    return data;
                });    
}



async function _add(data) { 
    let app_token = localStorage.getItem(accessToken);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+app_token
        },
        body: JSON.stringify(data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'leads', requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}

async function _update(data, id) {
    let app_token = localStorage.getItem(accessToken);
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+app_token
        },
        body: JSON.stringify(data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'leads/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}

async function _delete(ids) {
    let app_token = localStorage.getItem(accessToken);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+app_token
        },
        body: JSON.stringify({leads_ids: ids})

    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'remove-leads', requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}


async function _add_task(data) { 
    let app_token = localStorage.getItem(accessToken);
    const requestOptions = {
        method: 'POST',
        headers: {
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+app_token
        },
        body: data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'task', requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}

async function _close_lead(data) { 
    let app_token = localStorage.getItem(accessToken);
    const requestOptions = {
        method: 'POST',
        headers: {
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+app_token
        },
        body: data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'leads/close', requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}

async function _import_leads(data) { 
    let app_token = localStorage.getItem(accessToken);
    const requestOptions = {
        method: 'POST',
        headers: {
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+app_token
        },
        body: data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'leads/import', requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}

async function _export_leads(data) { 
    let app_token = localStorage.getItem(accessToken);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+app_token
        },
        body: JSON.stringify(data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'leads/export', requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}

async function _patch(data, id) { 
    let app_token = localStorage.getItem(accessToken);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+app_token
        },
        body: JSON.stringify(data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'leads/patch/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}