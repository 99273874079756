import React, {useState } from 'react'
import { Box, HStack, FormLabel, IconButton, InputGroup, InputRightElement  } from '@chakra-ui/react'
import { Input, Icon } from '.'

const FeaturesList = ({label, placeholder, onChange,   values,  ...rest}) => {

    const [ features, setFeatures ] = useState(values || [''])

    const handleInputChange = (index, value) => {             
        let allFeatures = [...features]
        allFeatures[index] = value
        setFeatures(allFeatures)
        onChange(allFeatures)
    }

    const addFeature = () => {
        let allFeatures = [...features]
        allFeatures.push('');
        setFeatures(allFeatures)
    }

    const removeFeature = (index) => {
        let allFeatures = [...features]
        allFeatures.splice(index, 1)
        setFeatures(allFeatures)
        onChange(allFeatures)
    }


    return(
        <>
            <HStack py="2" alignItems="center">
                <Box>{label && <FormLabel m="0" fontSize="12px" color="brand.600" fontWeight="600" textTransform="uppercase">{label}</FormLabel>}</Box>
                <IconButton onClick={() => addFeature()} rounded="full" variant="ghost" _hover={{ bg: 'brand.400', color: 'white' }} colorScheme="brand" size="xs" icon={<Icon fontSize="sm" color="currentcolor" name="plus-solid" />} />
            </HStack>
            <Box>
                {
                    features.map((feature, f) =>
                        <Box my="1" key={f}  >
                            <InputGroup>
                                <Input 
                                    variant="flushed" 
                                    value={feature } 
                                    onChange={(e) => handleInputChange(f, e.target.value)} 
                                    placeholder={placeholder}                                
                                />
                                {
                                    (features.length > 1) &&
                                    <InputRightElement 
                                        cursor="pointer"                            
                                        onClick = {() => removeFeature( f )}
                                        children={<Icon fontSize="sm" name="times-solid" color="red"  />}
                                    /> 
                                }                        
                            </InputGroup>
                        </Box>
                    )
                }
                
            </Box>

        </>
    )
}

export { FeaturesList }