export const USER_LOGOUT = 'USER_LOGOUT'

export const AuthConstants = {
    LOGIN: 'AUTH_LOGIN',
    LOGOUT: 'AUTH_LOGOUT',
    FAILED: 'AUTH_FAILED',
    AUTH: 'CHECK_USER_AUTH',
    UPDATE: 'UPDATE_PROFILE',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    REFRESH_TOKEN_FAILED: 'REFRESH_TOKEN_FAILED',
    CLEAR: 'AUTH_CLEAR',
    STATS: 'GET_STATS',
    REPORTS: 'GET_REPORTS'
}


export const RoleConstants = {
    GET_RESOURCES: 'GET_RESOURCES',
    GET: 'GET_ROLES',
    FAILED: 'ROLE_FAILED',
    ADD: 'ADD_ROLE',
    UPDATE: 'UPDATE_ROLE'
}

export const UserConstants = {
    GET: 'GET_USERS',
    FAILED: 'USERS_FAILED',
    ADD: 'ADD_USER',
    UPDATE: 'UPDATE_USER',
    TEAMS: 'GET_TEAMS',
    ADD_TEAM: 'ADD_TEAM',
    REMOVE_TEAM: 'REMOVE_TEAM',
    UPDATE_TEAM: 'UPDATE_TEAM',
    ALL_TEAMS: 'ALL_TEAMS',
    CLEAR: 'CLEAR_USERS',

}

export const ProjectConstants = {
    GET: 'GET_PROJECTS',
    FAILED: 'PROJECTS_FAILED',
    ADD: 'ADD_PROJECT',
    UPDATE: 'UPDATE_PROJECT',
    DELETE: 'DELETE_PROJECT',
    KEYVAL: 'GET_PROJECT_KEYVAL',
    KEYVALNESTED: 'GET_PROJECT_UNITS_KEYVAL',
    CLEAR: 'CLEAR_PROJECTS',
    CLEAR_VALS: 'CLEAR_PROJECTS_KEYVALS'
}

export const UnitConstants = {
    GET: 'GET_UNITS',
    FAILED: 'UNITS_FAILED',
    ADD: 'ADD_UNIT',
    UPDATE: 'UPDATE_UNIT',
    DELETE: 'DELETE_UNIT',
    KEYVAL: 'GET_UNIT_KEYVAL',
}

export const LeadConstants = {
    GET: 'GET_LEADS',
    FAILED: 'LEADS_FAILED',
    ADD: 'ADD_LEAD',
    UPDATE: 'UPDATE_LEAD',
    DELETE: 'DELETE_LEAD',
    DETAILS: 'GET_LEAD_DETAILS',
    CLEAR: 'CLEAR_LEAD',
    ASSIGN: 'ASSIGN_LEAD'
}

export const MetaConstants = {
    GET: 'GET_METADATA'
}

export const ClientConstants = {
    GET: 'GET_CLIENTS',
    FAILED: 'CLIENTS_FAILED',
    CLEAR: 'CLEAR_CLIENTS',
    DETAILS: 'GET_CLIENT_DETAILS'
}