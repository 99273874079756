import React, { useState, useEffect } from 'react'
import { Box, FormControl, Text, Tag, TagLabel, TagCloseButton, FormLabel, FormErrorMessage, Input, useBoolean, useOutsideClick, HStack } from '@chakra-ui/react'
import { Icon } from '.'
const AutoComplete = React.forwardRef( ({error, label, required, placeholder, options, value, onChange, showOnFocus, ...rest}, ref) => {

    const dropdownRef = React.useRef()
    const [show, setShow] = useBoolean(false)
    const [ values, setValues ] = useState(value || [])
    const [ filtered, setFiltered ] = useState(options)
    
    
    useOutsideClick({
        ref: dropdownRef,
        handler: () => {
            if(show)
                setShow.off()
        },
      })

    useEffect(() => {
        setValues(value)
    }, [value])

    useEffect(() => {
        setFiltered(options)
    }, [options])

    const handleFilter = (q) =>{
        let filterOptions = options.filter((x) => {            
            return x.label.toUpperCase().includes(q.toUpperCase())
        })
        
        setFiltered(filterOptions)
    }

    const handleSelect =(opt) => {
        let index = values.findIndex(val => val === opt)
        if(index > -1){
            values.splice(index, 1)
        }
        else{
            values.push(opt)
        }
        setValues(values)
        onChange(values)
    }

    const getLabel = (val) => {
        let label = options.find((o) => parseInt(o.value) === val)
        return label?.label
    }

    return(
        <Box  pos="relative">
            <FormControl isInvalid={error}>
            {label && <FormLabel fontSize="12px" color="brand.600" fontWeight="600" textTransform="uppercase">{label} {required && <Text as={'span'} fontSize="xs" color="red">*</Text>}</FormLabel>}
                {
                    (values?.length > 0) &&
                    <HStack>
                        {
                            values.map((val, v) =>
                            <Tag key={v} size="sm" rounded="sm" colorScheme="brand">
                                <TagLabel color="white">{getLabel(val)}</TagLabel>
                                <TagCloseButton onClick={() => handleSelect(val)} color="white" _focus={{ outline: 'none', shadow: 'none' }} fontSize="sm" />
                            </Tag>
                            )
                        }
                    </HStack>
                }
                <Box pos="relative">
                    <Input onFocus={setShow.on} placeholder={placeholder} onClick={setShow.on} onChange={(e) => handleFilter(e.target.value)}   {...rest} ref={ref} />
                    <Box ref={dropdownRef} d={show ? "block" : 'none'} bg="white" pos="absolute" zIndex={10} left={0}  top="100%" w="100%" shadow="xl" maxH="200px" overflowY="auto">
                        {
                            filtered?.length > 0 ?                                                
                                filtered.map((option, o) =>
                                <Box pos="relative" onClick={() => handleSelect(option?.value)}  px="4" _hover={{ bg: 'gray.50', color: 'brand.500' }} cursor="pointer" py="1.5" key={o}>
                                    <Text color="currentcolor" fontSize="sm">{option?.label}</Text>
                                    {
                                        values.includes(option.value) &&
                                        <Icon pos="absolute" right="4" top="2" name="check-solid" />
                                    }
                                </Box>
                                )
                            :
                            <Box pos="relative"  px="4" _hover={{ bg: 'gray.50', color: 'brand.500' }} cursor="pointer" py="1.5">
                                <Text color="currentcolor" fontSize="sm">No results found...</Text>                                
                            </Box>

                        }
                    </Box>
                    
                    
                </Box>
                <FormErrorMessage>{error}</FormErrorMessage>
            </FormControl>
        </Box>
    )
})

export { AutoComplete }