import React, { Suspense, lazy, useState,  useEffect} from 'react'
import { BrowserRouter as Router, Route, Routes as Switch } from 'react-router-dom'
import { Splash } from '../ui-elements'
import { Fade } from '@chakra-ui/react'
import { useApp } from '../hooks'
import { isEmpty } from '../helpers'
// import NotFound from '../pages/errors/404'
import { useNavigate } from 'react-router-dom'

const Login = lazy(() => import('./../pages/Auth'))
const ForgetPassword = lazy(() => import('./../pages/Auth/ForgetPassword'))
// const ResetPassword = lazy(() => import('./../pages/auth/ResetPassword'))
const Dashboard = lazy(() => import('./../pages/Dashboard'))


const RedirectToLogin = () => {
    const [ mount, setMount] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        if(!mount){
            setMount(true)
            navigate('/')
        }
      
    }, [mount, navigate])
    return(
        <></>
    )
}

const  Routes = () => {    
    
    const auth = useApp();
    
    

    if(auth.loading)
    {
        return(
            <Fade in={true}>
                <Splash />
            </Fade>
        )
    }

    if(isEmpty(auth.user) || !localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)) 
    {
        return (
            <Fade in={true}>
                <Suspense fallback={<div />}>
                    <Router>
                        <Switch>
                            <Route exact path="/" element={<Login/>} />
                            <Route path="*"  element={<RedirectToLogin />} />
                            <Route exact path="/forget-password" element={<ForgetPassword/>} />
                            {/* <Route exact path="/forget-password" element={<ForgetPassword/>} />
                            <Route exact path="/reset-password/:hash/:id" element={<ResetPassword/>} />
                            <Route path="*"  element={<NotFound />} />  */}
                        </Switch>
                    </Router>
                </Suspense>
            </Fade>
        )
    }

    return (                
            <Fade in={true}>
                <Suspense fallback={<div />}>
                    <Router>
                        <Switch>
                            <Route path="/*" element={<Dashboard/>} />
                        </Switch>
                    </Router>
                </Suspense>
            </Fade>                
    )
}

export default Routes;