import { combineReducers } from 'redux'
import { authReducer } from './auth.reducer';
import { clientReducer } from './client.reducer';
import { leadReducer } from './lead.reducer';
import { metaReducer } from './meta.reducer';
import { projectReducer } from './project.reducer';
import { roleReducer } from './role.reducer';
import { unitReducer } from './unit.reducer';
import { userReducer } from './user.reducer';

export default combineReducers({
    auth: authReducer,
    roles: roleReducer,
    users: userReducer,
    metas: metaReducer,
    projects: projectReducer,
    units: unitReducer,
    leads: leadReducer,
    clients: clientReducer
});
