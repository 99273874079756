import React, { useState } from 'react'
import { Box, HStack, VStack, FormLabel, Text, IconButton } from '@chakra-ui/react'
import { Icon, Tooltip } from '.'

const DocumentPicker = ({multiple=false, error, label, accept,  onSelect, docs=[]}) => {

    const [list, setList] = useState(docs || [])
    const fileRef = React.useRef();

    const handleFile = (e) => {
        if(e.target.files){
            if(multiple){
                let allFiles  = e.target.files
                let allList = []
                for (var i = 0; i < allFiles.length; i++) {
                        allList.push({
                            name: allFiles[i].name,
                            file: allFiles[i]
                        })
                }                
                setList([...list, ...allList ])
                onSelect([...list, ...allList ])
            }
            else{
                let file  = e.target.files[0]
                setList([file])
                onSelect([file])
            }
        }else{
            alert("Please select file(s)")
        }  
    }

    const triggerSelect = () =>{
        var event = new MouseEvent('click', {
            'view': window, 
            'bubbles': true, 
            'cancelable': false
           });           
           fileRef.current.dispatchEvent(event);
    }

    const removeFile = (index) => {
        let allFiles = [...list]
        allFiles.splice(index, 1)
        setList(allFiles)
        onSelect(allFiles)
    }

    return(
        <>
        <HStack py="0.25" alignItems="center">
            <Box>{label && <FormLabel m="0" fontSize="12px" color="brand.600" fontWeight="600" textTransform="uppercase">{label}</FormLabel>}</Box>
            <Tooltip label='Select files to upload' placement="end">
                <IconButton onClick={triggerSelect} rounded="full" variant="ghost" _hover={{ bg: 'brand.400', color: 'white' }} colorScheme="brand" size="xs" icon={<Icon fontSize="sm" color="currentcolor" name="file-alt-solid" />} />
            </Tooltip>
            <input multiple={multiple ? true : false}   accept={accept || "*/*"} style={{  display: "none" }} ref={fileRef} type="file" onChange={handleFile}/>
        </HStack>                
        <Box rounded="md">
            
            { 
                (list.length === 0 || !list) ?
                <></>
                :
                <Box py="3">
                    <VStack alignItems="flex-start">
                        {
                            list.map((item, t) => {
                                return(
                                    <HStack pl="2" rounded="sm" w="100%" key={t} justifyContent="space-between" py="0.25" _hover={{ bg: 'gray.100', color: 'brand.500' }}>
                                        <HStack>
                                            <Icon name="file-contract-solid" color="currentcolor" />
                                            <Text fontSize="sm" color="currentcolor"  isTruncated>{item?.name}</Text>
                                        </HStack>
                                        <IconButton onClick={() => removeFile(t)} size="xs" rounded="full" colorScheme="red" variant="ghost" icon={<Icon name="times-solid" color="currentcolor" />} />
                                    </HStack>
                                )
                            })                            
                        }
                    </VStack>
                </Box>
            }
        </Box>
        <Text color="red.500" fontSize="12px">{error}</Text>
        </>
    )
}

export { DocumentPicker }