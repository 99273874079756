import { AuthConstants as Constants } from '../types'
import { authApi as api } from '../../api'

export const authAction = {
    login,
    logout,
    stats,
    statsbydate,
    reports,
    auth_check,
    refresh_token,
    clear
};

function login(form_data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._login(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.LOGIN, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function auth_check() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._auth_check()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.AUTH, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function stats() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._stats()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.STATS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function statsbydate(form_data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._statsbydate(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.STATS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function reports(form_data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._reports(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.REPORTS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function logout() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._logout()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve()
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )
        });
    function success() { return { type: Constants.LOGOUT } }
    function failure() { return { type: Constants.FAILED } }
    }
}

function refresh_token(token) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._refresh(token)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.REFRESH_TOKEN, payload } }
    function failure(error) { return { type: Constants.REFRESH_TOKEN_FAILED, error } }
}

function clear() {
    return dispatch => {   
        return new Promise((resolve, reject) => {     
            dispatch(success());
            resolve()
        })   
    }
    function success() { return { type: Constants.CLEAR } }
}