import { UnitConstants as Constants } from '../types'

const initialState = {
    units: [],
    unit_vals: [],
};

export function unitReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,
                units: action.payload.units                
            };

        case Constants.KEYVAL:
            return {
                ...state,
                unit_vals: action.payload.vals                
            };
            
        case Constants.ADD:
            return {
                ...state,               
                units: [...state.units, action.payload.unit]
            };

        case Constants.UPDATE:
            let index = state.units.findIndex((item) => parseInt(item.id) === parseInt(action.payload.unit.id));
            let itemsArray = [...state.units];
            if(index > -1)
                itemsArray[index] = action.payload.unit
            return {
                ...state,               
                units: itemsArray
            };
        
        case Constants.DELETE:
            return{
                ...state,
                units: state.units.filter((x) => parseInt(x.id) !== parseInt(action.payload.id))
            }

        case Constants.FAILED:
            return{
                ...state
            }

        default:
            return state
    }
}