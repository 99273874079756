export const colors = {
    brand: {
        50: "#F3FCD9",
        100: "#F3FCD9",
        200: "#E5F9B5",
        300: "#CCEE8C",
        400: "#B1DD6B",
        500: "#8DC73F",
        600: "#70AB2E",
        700: "#568F1F",
        800: "#3E7314",
        900: "#2D5F0C",
    },
    dark:{
        50: "#F0F2F6",
        100: "#F0F2F6",
        200: "#E1E5ED",
        300: "#BABEC9",
        400: "#868993",
        500: "#43454C",
        600: "#303441",
        700: "#212536",
        800: "#15182C",
        900: "#0C0F24",
    },
    flat:{            
        tomato: "#FB4630",
        pink: "#FE445F",
        purple: "#773DE3",
        indigo: "#B28FF0",
        navy: "#3C50BC",
        blue: "#3389E1",
        red: '#FC5C65',
        red2: "#B40A28",
        'dark-green': '#397655',
        'dark-blue': "#629CC6",
        aqua: "#00C3ED",
        teal: "#009988",
        green: "#77C33E",
        lime: "#A8E172",
        yellow: "#FFCC34",
        orange: "#FFB718",
        gray: "#616C78"
    },
    muted:{            
        tomato: "rgba(251,70,48, 0.25)",
        pink: "rgba(254,68,95, 0.25)",
        purple: "rgba(119,61,227, 0.25)",
        indigo: "rgba(178,141,240, 0.25)",
        navy: "rgba(60,80,188, 0.25)",
        blue: "rgba(51,137,225, 0.25)",
        aqua: "rgba(0,195,237, 0.25)",
        teal: "rgba(0,189,153, 0.25)",
        green: "rgba(119,195,62, 0.25)",
        lime: "rgba(168,225,114, 0.25)",
        yellow: "rgba(255,204,52, 0.25)",
        orange: "rgba(255,183,24, 0.25)",
        gray: "rgba(97,108,120, 0.25)",
    }      
}