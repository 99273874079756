import { extendTheme } from "@chakra-ui/react"
import { colors, button, input, textarea, select } from "./styles"
import { custom } from "./styles/custom"

export const theme = extendTheme({
    styles:{
        global: custom
    },
    colors: colors,
    components:{
        Button: button,
        Heading:{
            baseStyle: {
                color: 'gray.500'
            },
            variants:{
                "page":{
                   fontSize: '22px',
                   color: 'gray.600',
                   fontWeight: '600'
                }
            }
        },
        FormLabel:{
            baseStyle: {
                color: 'gray.500'
            }
        },
        Text:{
            baseStyle: {
                color: 'gray.500'
            }
        },
        Input: input,
        Textarea: textarea,
        Select: select,
        Switch: {
            baseStyle: {
                backgroundColor: 'brand',
                rounded: 'md',
                _focus: { 
                    boxShadow: 'none' 
                }
            },
        },
        Menu:{
            parts: ['menu', 'item', 'list'],      
            baseStyle:{
                shadow: 'xl'
            },  
            sizes: {
                sm: { 
                    list:{
                        shadow: '3xl',
                        rounded: 'none',
                        minW: '150px',
                        py: 0,
                    },                   
                    item: {
                        fontSize: '0.85rem',
                        px: 3,
                        py: 2,
                    },                    
                },
            },
            variants:{
                lead:{
                    list:{
                        py: 2,
                        border:0,
                        minW: '180px',
                        rounded: 'md'
                    },
                    item: {
                        fontSize: '0.85rem',
                        color: 'gray.500',
                        px: 4,
                        py: 1,
                        _hover:{
                            bg: 'transparent',
                            color: 'brand.500'
                        }
                    }, 
                }
            }
        },
        Tooltip:{
            baseStyle:{
                fontWeight: '400',
                fontSize: '12px',                
                shadow: 'none',
            }
        },
        Radio:{
            parts: ['control', 'label'],
            sizes:{
                sm: {
                    control: { width: 3, height: 3, _focus: {shadow: 'none'} },
                    label: { fontSize: "13px" },
                  }
            },
            defaultProps:{
                colorScheme: "brand",
            }
        },
        Checkbox:{
            parts: ['control', 'label', 'icon'],
            variants:{
                lead: {
                    control: { width: 3.5, height: 3.5, _focus: {shadow: 'none'} },
                    label: { fontSize: "13px" },
                    icon: {fontSize: '6px'}
                  }
            },
            defaultProps:{
                colorScheme: "brand",
            }
        }   
    }
})