import React, {useState, useEffect} from 'react'
import {
    Box, Input, InputGroup, FormControl, FormErrorMessage, FormLabel, InputLeftElement, InputRightElement,
    useBoolean, IconButton,
    Popover, PopoverTrigger, PopoverContent, PopoverBody
} from '@chakra-ui/react'
import { Icon } from '.'
import ReactDatePicker from 'react-datepicker'
import dayjs from 'dayjs';

const DatePicker = ({icon, label, variant,  placeholder, size, error, minTime, maxTime, timeIntervals, minDate, onChange, maxDate, format="DD/MM/YYYY",  selected, showTime, tooltip }) => {

    const [isOpen, setIsOpen] = useState(false)
    const [value, setValue] = useState(selected)
    const [ clear, setClear ] = useBoolean(false) 

    useEffect(() => {
      if(selected)
        setValue(selected)
      else
        clearDate()
    }, [selected])

    useEffect(() => {
        if(!selected && minDate)
        setValue(minDate)
    }, [minDate, selected])

    function handleInputChange(value)
    {
        setIsOpen(false)
        setValue(value)
        onChange(value)
        setClear.off()
    }

    function clearDate (e = null) {        
        if(e){
            e.preventDefault()        
        }
        setValue(new Date())
        onChange(null)
        setClear.on()
    }

    return(
            <>
            <Box pos="relative">
                <Popover
                    matchWidth={true}
                    isOpen={isOpen}
                    gutter="0"                    
                    onClose={() => setIsOpen(false)}
                    >
                    <PopoverTrigger>
                        <FormControl isInvalid={error}>
                            { label && <FormLabel fontSize="12px" color="brand.600" fontWeight="600" textTransform="uppercase">{label}</FormLabel>}
                            <InputGroup size={size || 'md'}>
                                {
                                    (icon) &&
                                    <InputLeftElement 
                                        pointerEvents="none"
                                        children={<Icon name={icon}  />}
                                    />                                
                                } 
                                <Input variant={variant}  onClick={() => setIsOpen(true)} placeholder={placeholder || "Select Date"} readOnly value={ clear ? '' : dayjs(value).format(format)} />                
                                {
                                    !clear &&
                                    <InputRightElement                             
                                        children={<IconButton onClick={(e) => clearDate(e)} rounded="full" variant="ghost" colorScheme="gray" size="xs" icon={<Icon fontSize="xs" name="times-solid" color="currentcolor" />} />}
                                    /> 
                                }                        
                            </InputGroup>
                            <FormErrorMessage>{error}</FormErrorMessage>
                        </FormControl>
                    </PopoverTrigger>
                    <PopoverContent w="100%" rounded="0" _focus={{ shadow: 'none' }}>
                        <PopoverBody>
                            <ReactDatePicker 
                                inline 
                                minDate={minDate} 
                                maxDate={maxDate}
                                minTime={minTime}
                                maxTime={maxTime}
                                showTimeSelect={showTime}
                                selected={value} 
                                timeIntervals={timeIntervals || 5}
                                dateFormat={format}
                                timeFormat="HH:mm"                        
                                // onSelect={(date) => handleInputChange(date)} 
                                onChange={(date) => handleInputChange(date)} 
                            />
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
                
                
            </Box>
            {/* <Modal
                open={isOpen}
                size="md"
                p={0}
                transition="slideInBottom"
                title={tooltip || "Select Date..."}
                scrollType="inline"
                bg="white"
                onClose={() => setIsOpen(false)}
                >
                    <ReactDatePicker 
                        inline 
                        minDate={minDate} 
                        maxDate={maxDate}
                        minTime={minTime}
                        maxTime={maxTime}
                        showTimeSelect={showTime}
                        selected={value} 
                        timeIntervals={timeIntervals || 5}
                        dateFormat={format}
                        timeFormat="HH:mm"                        
                        // onSelect={(date) => handleInputChange(date)} 
                        onChange={(date) => handleInputChange(date)} 
                    />
                    <Box h="20px"></Box>
            </Modal> */}
            
        
        </>
    )
}

export {DatePicker}