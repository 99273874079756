import { ClientConstants as Constants } from '../types'
import { clientApi as api } from '../../api'

export const clientAction = {
    get,      
    details, 
    clear
};

function get(filter="", offset=0) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get(filter, offset)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function details(id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._details(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.DETAILS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function clear() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(success());
            resolve()            
        });
    }
    function success() { return { type: Constants.CLEAR } }
}
