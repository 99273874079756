import {handleResponse, requestTimeout} from '../helpers'

const apiUrl = process.env.REACT_APP_API_URL
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const clientApi = {
    _get,
    _details
};


async function _get(filter, offset){
    const app_token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        }        
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'clients/'+offset, requestOptions))
                .then(handleResponse)
                .then( (data) => {                
                    return data;
                });    
}

async function _details(id){
    const app_token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        }        
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'clients/details/'+id, requestOptions))
                .then(handleResponse)
                .then( (data) => {                
                    return data;
                });    
}

