import { UserConstants as Constants } from '../types'

const initialState = {
    users: [],
    teams: [],
    all_teams: []
};

export function userReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,
                users: action.payload.users                
            };
            
        case Constants.ADD:
            return {
                ...state,               
                users: [...state.users, action.payload.user]
            };

        case Constants.UPDATE:
            let index = state.users.findIndex((item) => parseInt(item.id) === parseInt(action.payload.user.id));
            let itemsArray = [...state.users];
            if(index > -1)
                itemsArray[index] = action.payload.user
            return {
                ...state,               
                users: itemsArray
            };
        
        case Constants.TEAMS:
            return {
                ...state,
                teams: action.payload.teams
            };

        case Constants.ALL_TEAMS:
            return {
                ...state,
                all_teams: action.payload.all_teams
            };

        case Constants.ADD_TEAM:
            return {
                ...state,               
                teams: [...state.teams, action.payload.team]
            };

        case Constants.UPDATE_TEAM:
            // let t_index = state.teams.findIndex((item) => parseInt(item.id) === parseInt(action.payload.team.id));
            // let t_itemsArray = [...state.teams];
            // if(t_index > -1)
            //     t_itemsArray[t_index] = action.payload.team
            return {
                ...state,               
                // teams: t_itemsArray
            };
            
        case Constants.REMOVE_TEAM:
            return{
                ...state,
                teams: state.teams.filter((x) => parseInt(x.id) !== parseInt(action.payload.id))
            }
        case Constants.FAILED:
            return{
                ...state
            }

        case Constants.CLEAR:
            return{
                ...state,
                users:[]
            }

        default:
            return state
    }
}