import React from 'react'
import {
    FormControl,
    FormLabel,
    Input as ChakraInput,
    Textarea, Text, InputGroup, InputLeftElement,
    FormErrorMessage,
    InputRightElement,
  } from "@chakra-ui/react"
import { Icon } from '.'
  
const Input = React.forwardRef( ({error, icon, clearable, required=false, onClear,  size, label, textarea = false, ...props}, ref) => {
    return(
        <FormControl isInvalid={error}>
            {label && <FormLabel fontSize="12px" color="brand.600" fontWeight="600" textTransform="uppercase">{label} {required && <Text as={'span'} fontSize="xs" color="red">*</Text>}</FormLabel>}
            <InputGroup size={size || 'md'}>                
                {
                    icon &&
                    <InputLeftElement>
                        <Icon name={icon} fontSize="sm" mt="-2" color="gray.300" />
                    </InputLeftElement>
                }
                { textarea ? <Textarea  {...props} ref={ref} /> :  <ChakraInput {...props} ref={ref} />}            
                {
                    (clearable && props.value) &&
                    <InputRightElement onClick={() => onClear()}>
                        <Icon  name="times-solid" fontSize="sm" mt="-1.5" />
                    </InputRightElement>
                }
            </InputGroup>
            <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
    )
})
export {Input}